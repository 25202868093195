export interface Employee {
  id?: string;
  seniority: Seniority;
  email: string;
}

export enum Seniority {
  Junior = "Júnior",
  MidLevel = "Pleno",
  Senior = "Sênior",
}

export interface Config {
  commissionPercentage: CommissionPercentageConfig;
}

export type CommissionPercentageConfig = {
  [key in Seniority]: {
    mainAdvisor: number;
    supportAdvisor: number;
    finder: number;
  };
};
