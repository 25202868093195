import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import { Broker, Comission, ComissionSheet } from "../types/BrokerAccount";
import { CommissionPercentageConfig } from "../types/Employee";
import { getComissionsPercentageConfig } from "./ConfigCRUD";
import { getAccountByID } from "./CustomerCRUD";
import { getEmployeeByID } from "./EmployeeCRUD";

interface MissingAccount {
  accountNumber: string;
  broker: Broker;
  customerName: string;
}

interface ReadComissionArrayResponse {
  success: boolean;
  missingData?: MissingData;
}

export interface MissingData {
  missingAccount: MissingAccount[];
  missingAdvisor: string[];
}

export const readComissionArray = async (
  arrayOfComissions: ComissionSheet[],
  date: string
): Promise<ReadComissionArrayResponse> => {
  const missingAccount: MissingAccount[] = [];
  const missingAdvisor: string[] = [];

  for (const comission of arrayOfComissions) {
    let clientAccount = await getAccountByID(
      comission.Corretora.toUpperCase() + "_" + comission.Conta
    );

    if (clientAccount === undefined) {
      missingAccount.push({
        accountNumber: comission.Conta,
        broker: comission.Corretora as Broker,
        customerName: comission.Cliente,
      });
      continue;
    }

    let comissionAdvisorID = await getEmployeeByID(comission.Assessor);
    if (comissionAdvisorID === undefined) {
      missingAdvisor.push(comission.Assessor);
    }
  }

  if (missingAccount.length > 0 || missingAdvisor.length > 0)
    return {
      success: false,
      missingData: {
        missingAccount,
        missingAdvisor,
      },
    };

  const config = await getComissionsPercentageConfig();
  const comissions: Comission[] = [];

  for (const comission of arrayOfComissions) {
    comissions.push(await calculaComissao(comission, config));
  }

  const comissionsRef = doc(db, "Comission", date);
  await setDoc(comissionsRef, { comissions });

  return {
    success: true,
  };
};

const calculaComissao = async (
  comission: ComissionSheet,
  config: CommissionPercentageConfig
): Promise<Comission> => {
  const accountNumber =
    comission.Corretora.toUpperCase() + "_" + comission.Conta;
  const clientAccount = await getAccountByID(accountNumber);

  const mainAdvisorId = clientAccount.roles.mainAdvisorId;
  let mainAdvisorComission = 0;
  let mainAdvisorPercentage = 0;
  if (mainAdvisorId !== "ESCRITÓRIO") {
    const mainAdvisor = await getEmployeeByID(mainAdvisorId);
    mainAdvisorPercentage = config[mainAdvisor.seniority].mainAdvisor / 100;
    mainAdvisorComission = comission.Comissão * mainAdvisorPercentage;
  }

  const supportAdvisorId = clientAccount.roles.supportAdvisorId;
  let supportAdvisorComission = 0;
  let supportAdvisorPercentage = 0;
  if (supportAdvisorId !== "ESCRITÓRIO") {
    const supportAdvisor = await getEmployeeByID(supportAdvisorId);
    supportAdvisorPercentage =
      config[supportAdvisor.seniority].supportAdvisor / 100;
    supportAdvisorComission = comission.Comissão * supportAdvisorPercentage;
  }

  const finderId = clientAccount.roles.finderId;
  let finderComission = 0;
  let finderPercentage = 0;
  if (finderId !== "ESCRITÓRIO") {
    const finder = await getEmployeeByID(finderId);
    finderPercentage = config[finder.seniority].finder / 100;
    finderComission = comission.Comissão * finderPercentage;
  }

  const officePercentage =
    1 - mainAdvisorPercentage - supportAdvisorPercentage - finderPercentage;

  const comissionObject: Comission = {
    account: {
      accountNumber: accountNumber,
      broker: comission.Corretora as Broker,
    },
    distribution: [
      {
        employeeId: mainAdvisorId,
        value: mainAdvisorComission,
      },
      {
        employeeId: supportAdvisorId,
        value: supportAdvisorComission,
      },
      {
        employeeId: finderId,
        value: finderComission,
      },
      {
        employeeId: "ESCRITÓRIO",
        value: officePercentage * comission.Comissão,
      },
    ].filter((item) => item.value !== 0),
    totalValue: comission.Comissão,
    category: comission.Categoria,
  };

  return comissionObject;
};

export const getComissionDates = async () => {
  const comissionsCollectionRef = collection(db, "Comission");
  const comissions = await getDocs(comissionsCollectionRef);
  if (!comissions) {
    throw "Erro ao retornar comissões!";
  }

  const comissionDatesList: string[] = [];

  comissions.docs.map((doc) => comissionDatesList.push(doc.id));

  return comissionDatesList;
};

export const getComissions = async (date: string) => {
  const comissionsRef = doc(db, "Comission", date);
  const comissions = await getDoc(comissionsRef);
  if (!comissions) {
    throw "Erro ao retornar as comissões!";
  }

  return comissions.data() as {
    comissions: Comission[];
  };
};
