const color = {
  background: "#1B1B1B",
  highlight: "#f29946",
  disclaimer: "#BDBFC1",
  backgroundDark: "#1B3960",
  states: {
    success: "#25be60",
    failed: "#ff4e40",
  },
};

export default color;
