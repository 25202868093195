export function CPFFormat(cpf: string) {
  const digitsCompleted = (cpf + "").padStart(11, "0");
  const cpfNumber = digitsCompleted.replace(/[^\d]/g, "");
  return cpfNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function toBRCurrency(number: number) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(number);
}

export function toNumberCurrency(num: number) {
  return Math.round(num * 100) / 100;
}

export const MONTHS_LABEL = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
};
