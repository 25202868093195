import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

export const UserContext = createContext<
  [string | null | undefined, (uid: string | undefined) => void]
>([undefined, () => null]);

function App() {
  const [uid, setUid] = useState<string | null | undefined>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setUid(localStorage.getItem("uid"));
    setLoaded(true);
  }, []);

  if (!loaded) return <p>Carregando...</p>;

  return (
    <BrowserRouter>
      <UserContext.Provider value={[uid, (uid) => setUidWithLocalStorage(uid)]}>
        <div style={styles.default}>
            <Routes/>
        </div>
      </UserContext.Provider>
    </BrowserRouter>
  );

  function setUidWithLocalStorage(uid: string | undefined) {
    if (uid) localStorage.setItem("uid", uid);
    else localStorage.removeItem("uid");
    setUid(uid);
  }
}

export default App;

const styles = {
    default: {
      backgroundColor: "#262626",
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      justifycontent: "center",
      color: "white",
      padding: "8px 32px 8px 32px",
      minHeight: "100vh",
    },
  };