import React, { useEffect, useState } from "react";
import { button, card, input } from "../../styles/components";
import Spacer from "../../components/Spacer";
import { useNavigate } from "react-router";
import { getAccount } from "../../controllers/CustomerCRUD";
import { Account } from "../../types/BrokerAccount";
import { CPFFormat } from "../../util";

export default () => {
  const navigate = useNavigate();

  const [costumersList, setCostumersList] = useState<Account[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getAccount()
      .then((data) => {
        setCostumersList(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <p>Carregando...</p>;

  if (isError) return <p>Falha ao buscar os dados de clientes</p>;

  const filteredCostumers = costumersList!.filter(
    (costumerAccount) =>
      costumerAccount.customerName.toLowerCase().match(search.toLowerCase()) ||
      costumerAccount.id.toLowerCase().match(search.toLowerCase())
  );

  return (
    <>
      <div style={styles.container}>
        <button
          type="button"
          style={button}
          onClick={() => navigate("/cadastrar-clientes")}
        >
          Carregar lista de clientes
        </button>
      </div>
      <Spacer size={32} />
      <input
        type="text"
        placeholder="Pesquisar"
        style={input}
        value={search}
        onChange={(input) => setSearch(input.target.value)}
      />
      <Spacer size={32} />
      {filteredCostumers.map((account, index, arr) => {
        const splitId = account.id.split("_");
        const broker = splitId[0];
        const accountNumber = splitId[1];

        return (
          <>
            <div style={card} key={index}>
              <div>
                <h3 style={{ margin: 0 }}>{account.customerName}</h3>
                <p>
                  CPF:{" "}
                  <b>
                    {account.customerCpf ? CPFFormat(account.customerCpf) : "-"}
                  </b>
                </p>
                <p>
                  Segmento: <b>{account.segment}</b>
                </p>
                <p>
                  Corretora: <b>{broker}</b>
                </p>
                <p>
                  Número da conta: <b>{accountNumber}</b>
                </p>
                <p>
                  Assessor principal: <b>{account.roles.mainAdvisorId}</b>
                </p>
                <p>
                  Assessor de apoio: <b>{account.roles.supportAdvisorId}</b>
                </p>
                <p>
                  Finder: <b>{account.roles.finderId}</b>
                </p>
                <p>{account.observations}</p>
              </div>
            </div>
            {index !== arr.length - 1 && <Spacer size={16} />}
          </>
        );
      })}
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-end",
  },
};
