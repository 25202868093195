import React, { useContext } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { UserContext } from "./App";
import MainMenu from "./components/MainMenu";
import Login from "./screens/login/Login";
import EmployeesList from "./screens/employees/EmployeesList";
import EmployeeCreate from "./screens/employees/EmployeeCreate";
import Settings from "./screens/settings/Settings";
import CostumersSheetRead from "./screens/costumers/CostumersSheetRead";
import CostumersList from "./screens/costumers/CostumersList";
import ReportsList from "./screens/reports/ReportsList";
import ReportsSheetRead from "./screens/reports/ReportsSheetRead";
import Results from "./screens/results/Results";

export default function MainRoutes() {
  const [user, _] = useContext(UserContext);

  return (
    <Routes>
      {user ? (
        <>
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route
            path="/"
            element={
              <MainMenu>
                <CostumersList />
              </MainMenu>
            }
          />
          <Route
            path="/assessores"
            element={
              <MainMenu>
                <EmployeesList />
              </MainMenu>
            }
          />
          <Route
            path="/editar-assessor/:id?"
            element={
              <MainMenu>
                <EmployeeCreate />
              </MainMenu>
            }
          />
          <Route
            path="/cadastrar-clientes"
            element={
              <MainMenu>
                <CostumersSheetRead />
              </MainMenu>
            }
          />
          <Route
            path="/relatorios"
            element={
              <MainMenu>
                <ReportsList />
              </MainMenu>
            }
          />
          <Route
            path="/enviar-relatorio/:month/:year"
            element={
              <MainMenu>
                <ReportsSheetRead />
              </MainMenu>
            }
          />
          <Route
            path="/analise/:month/:year"
            element={
              <MainMenu>
                <Results />
              </MainMenu>
            }
          />
          <Route
            path="/configuracoes"
            element={
              <MainMenu>
                <Settings />
              </MainMenu>
            }
          />
        </>
      ) : (
        <>
          <Route path="*" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
        </>
      )}
    </Routes>
  );
}
