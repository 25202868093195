import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { MONTHS_LABEL, toBRCurrency, toNumberCurrency } from "../../util";
import color from "../../styles/color";
import Spacer from "../../components/Spacer";
import { Employee } from "../../types/Employee";
import { getEmployees } from "../../controllers/EmployeeCRUD";
import { Broker, Comission } from "../../types/BrokerAccount";
import { getComissions } from "../../controllers/ComissionCRUD";
import { downloadExcel } from "react-export-table-to-excel";
import { button } from "../../styles/components";

export default () => {
  const tableRef = useRef(null);

  const { month, year } = useParams();
  const formattedMonth = ("00" + month).slice(-2);
  const date = formattedMonth + "-" + year;

  const [employeesList, setEmployeesList] = useState<Employee[]>();

  const [isLoading, setIsLoading] = useState(true);

  const [comissions, setComissions] = useState<Comission[]>();
  const [isComissionsLoading, setIsLoadingComissions] = useState(true);

  const [selectedEmployee, setSelectedEmployee] = useState<string>("all");
  const [selectedBroker, setSelectedBroker] = useState<Broker | "all">("all");

  const allCategories = comissions?.map((comission) => comission.category);
  const categories = [...new Set(allCategories)];
  const [selectedCategory, setSelectedCategory] = useState<string>("all");

  const filteredByBroker = comissions?.filter(
    (item) => selectedBroker === "all" || item.account.broker === selectedBroker
  );

  const filteredByCategories = filteredByBroker?.filter(
    (item) => selectedCategory === "all" || item.category === selectedCategory
  );

  const filteredByEmployeeComissions = filteredByCategories
    ?.map((comission) => {
      const withDataDistribution = comission.distribution.map((dist) => {
        return {
          ...dist,
          broker: comission.account.broker,
          accountNumber: comission.account.accountNumber.split("_")[1],
          totalValue: comission.totalValue,
        };
      });
      return withDataDistribution.filter((distribution) => {
        return (
          selectedEmployee === "all" ||
          distribution.employeeId === selectedEmployee
        );
      });
    })
    .flat();

  const totalValue =
    filteredByEmployeeComissions && filteredByEmployeeComissions.length
      ? filteredByEmployeeComissions.reduce((acc, currentValue) => {
          return acc + currentValue.value;
        }, 0)
      : 0;

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getEmployees()
      .then((data) => {
        setEmployeesList(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });

    getComissions(date).then((data) => {
      setComissions(data.comissions);
      setIsLoadingComissions(false);
    });
  }, []);

  if (isLoading || isComissionsLoading) return <p>Carregando...</p>;

  return (
    <>
      <h1 style={styles.title}>
        {/**@ts-ignore */}
        {MONTHS_LABEL[month!]} de {year}
      </h1>
      <Spacer size={16} />
      <div style={styles.filterContainer}>
        <div>
          <p>Assessor</p>
          <select
            style={styles.select}
            value={selectedEmployee}
            onChange={(k) => setSelectedEmployee(k.target.value)}
          >
            <option value="all">TODOS</option>
            <option value="ESCRITÓRIO">ESCRITÓRIO</option>
            {employeesList?.map((item) => (
              <option value={item.id}>{item.id}</option>
            ))}
          </select>
        </div>
        <Spacer size={16} row />
        <div>
          <p>Corretora</p>
          <select
            style={styles.select}
            value={selectedBroker}
            onChange={(k) => setSelectedBroker(k.target.value as Broker)}
          >
            <option value="all">TODAS</option>
            <option value="Ágora">Ágora</option>
            <option value="Genial">Genial</option>
            <option value="Guide">Guide</option>
            <option value="Necton">Necton</option>
            <option value="Órama">Órama</option>
          </select>
        </div>
        <Spacer size={16} row />
        <div>
          <p>Categoria</p>
          <select
            style={styles.select}
            value={selectedCategory}
            onChange={(k) => setSelectedCategory(k.target.value)}
          >
            <option value="all">TODAS</option>
            {categories?.map((category) => (
              <option value={category}>{category}</option>
            ))}
          </select>
          <Spacer size={16} row />
        </div>
      </div>
      <Spacer size={32} />
      <h3 style={styles.subtitle}>Comissões totais:</h3>
      <Spacer size={2} />
      <label style={styles.numberExibition}>{toBRCurrency(totalValue)}</label>
      <Spacer size={32} />
      <h3 style={styles.subtitle}>Origem das comissões:</h3>
      <table ref={tableRef}>
        <tbody>
          <tr>
            <th>Corretora</th>
            <th>Conta</th>
            <th>Valor total da comissão</th>
            <th>Valor recebido</th>
          </tr>
          {filteredByEmployeeComissions?.map((item) => {
            return (
              <tr>
                <td>{item.broker}</td>
                <td>{item.accountNumber}</td>
                <td>{toBRCurrency(item.totalValue)}</td>
                <td>{toBRCurrency(item.value)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Spacer size={32} />
      <button style={button} onClick={handleDownloadExcel}>
        {" "}
        Exportar Planilha Excel{" "}
      </button>
    </>
  );

  function handleDownloadExcel() {
    const header = [
      "Corretora",
      "Conta",
      "Valor total da comissão",
      "Valor recebido",
    ];

    const body = filteredByEmployeeComissions
      ? filteredByEmployeeComissions.map((item) => {
          return [
            item.broker,
            item.accountNumber,
            toNumberCurrency(item.totalValue),
            toNumberCurrency(item.value),
          ];
        })
      : [];

    downloadExcel({
      fileName: "comissions",
      sheet: "Planilha 1",
      tablePayload: {
        header,
        body,
      },
    });
  }
};

const styles = {
  title: {
    textAlign: "center" as "center",
    color: color.disclaimer,
  },
  filterContainer: {
    display: "flex",
    paddingLeft: "32px",
    paddingRight: "32px",
    flex: 1,
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    flexWrap: "wrap" as "wrap",
  },
  select: {
    backgroundColor: color.backgroundDark,
    border: "none",
    color: "white",
    padding: 16,
    borderRadius: 8,
    outline: "none",
    width: "300px",
  },
  subtitle: {
    textAlign: "center" as "center",
    color: color.disclaimer,
  },
  numberExibition: {
    textAlign: "center" as "center",
    color: "white",
    fontSize: 32,
  },
};
