import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { button, card, inputLabel } from "../../styles/components";
import Spacer from "../../components/Spacer";
import { Employee, Seniority } from "../../types/Employee";
import { getEmployeeByID, putEmployee } from "../../controllers/EmployeeCRUD";

export default () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [employeeName, setEmployeeName] = useState(id);
  const [employee, setEmployee] = useState<Employee>({
    email: "",
    seniority: Seniority.Junior,
  });
  const [isLoading, setIsLoading] = useState(!!id);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (id) {
      getEmployeeByID(id)
        .then((data) => {
          setEmployee(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading) return <p>Carregando...</p>;

  if (isError) return <p>Falha ao buscar os dados do assessor</p>;

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Cadastro de Assessor</h1>
      <Spacer size={16} />
      <label style={inputLabel}>Nome:</label>
      <input
        type="text"
        value={employeeName}
        onChange={(k) => setEmployeeName(k.target.value)}
        style={card}
      />
      <label style={inputLabel}>Email:</label>
      <input
        type="text"
        value={employee.email}
        onChange={(k) => setEmployee({ ...employee, email: k.target.value })}
        style={card}
      />
      <label style={inputLabel}>Senioridade:</label>
      <select
        value={employee.seniority}
        onChange={(k) =>
          setEmployee({ ...employee, seniority: k.target.value as Seniority })
        }
        style={card}
      >
        <option value={Seniority.Junior} style={{ border: "none" }}>
          Júnior
        </option>
        <option value={Seniority.MidLevel}>Pleno</option>
        <option value={Seniority.Senior}>Sênior</option>
      </select>
      <Spacer size={32} />
      <div style={{ alignSelf: "center" }}>
        <button
          style={button}
          onClick={() =>
            putEmployee(employee, employeeName)
              .then(() => {
                alert("Assessor atualizado com sucesso");
                navigate("/assessores");
              })
              .catch(() =>
                alert(
                  "Falha ao atualizar o assessor. Tente novamente mais tarde."
                )
              )
          }
        >
          Salvar
        </button>
      </div>
    </>
  );
};
