import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import { CommissionPercentageConfig } from "../types/Employee";

const comissionsDocRef = doc(db, "Config", "commissionPercentage");

export const getComissionsPercentageConfig = async () => {
  const config = await getDoc(comissionsDocRef);

  if (!config) {
    throw "Erro ao retornar configurações!";
  }

  return config.data() as CommissionPercentageConfig;
};

export const putComissionsPercentageConfig = async (
  Config: CommissionPercentageConfig
) => {
  const configRef = doc(db, "Config", "commissionPercentage");
  await setDoc(configRef, Config);
};
