import color from "./color";

export const button = {
  height: "54px",
  padding: "16px 32px 16px 32px",
  borderRadius: 8,
  border: "none",
  backgroundColor: color.backgroundDark,
  color: color.disclaimer,
  fontSize: 16,
  cursor: "pointer",
};

export const disabledButton = {
  height: "54px",
  padding: "16px 32px 16px 32px",
  borderRadius: 8,
  border: "none",
  backgroundColor: color.disclaimer,
  color: color.backgroundDark,
  fontSize: 16,
};

export const input = {
  fontSize: 18,
  padding: 16,
  borderRadius: 8,
  backgroundColor: color.backgroundDark,
  border: "none",
  outline: "none",
  color: color.disclaimer,
};

export const card = {
  fontSize: 18,
  padding: 16,
  borderRadius: 8,
  backgroundColor: color.backgroundDark,
  border: "none",
  color: "white",
  display: "flex",
  justifyContent: "space-between",
  outline: "none",
};

export const inputLabel = {
  color: color.disclaimer,
  margin: "16px 8px 8px 8px",
};
