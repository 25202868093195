import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useContext, useState } from "react";
import { UserContext } from "../../App";
import LogoSpace from "../../assets/logo_meio_claro.png";
import { button, input } from "../../styles/components";
import Spacer from "../../components/Spacer";

export default () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setUser = useContext(UserContext)[1];

  const auth = getAuth();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <img src={LogoSpace} style={{ height: 72 }} />
      <Spacer size={32} />
      <input
        placeholder="Email"
        style={input}
        size={25}
        required
        type="email"
        id="email"
        value={email}
        onChange={(k) => setEmail(k.target.value)}
      />
      <Spacer size={16} />
      <input
        placeholder="Senha"
        style={input}
        size={25}
        required
        type="password"
        id="password"
        value={password}
        onChange={(k) => setPassword(k.target.value)}
      />
      <Spacer size={16} />
      <button
        style={button}
        onClick={() =>
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              const userInfo = userCredential.user;
              setUser(userInfo.uid);
            })
            .catch((error) => {
              alert(`Erro ao fazer login!`);
            })
        }
      >
        Entrar
      </button>
    </div>
  );
};
