import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import color from "../../styles/color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faUpload,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import Spacer from "../../components/Spacer";
import { card } from "../../styles/components";
import { MONTHS_LABEL } from "../../util";
import { useNavigate } from "react-router";
import { getComissionDates } from "../../controllers/ComissionCRUD";

export default () => {
  const navigate = useNavigate();

  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());
  const [filledMonths, setFilledMonths] = useState<string[]>();

  useEffect(() => {
    const getComissions = async () => {
      const comissions = await getComissionDates();
      setFilledMonths(comissions);
    };
    getComissions();
  }, []);

  if (!filledMonths) return <p>Carregando...</p>;

  return (
    <>
      <div style={styles.titleContainer}>
        <FontAwesomeIcon
          icon={faChevronLeft}
          size={"2x"}
          color={color.disclaimer}
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedYear(selectedYear - 1)}
        />
        <Spacer size={4} row />
        <h2 style={styles.title}>{selectedYear}</h2>
        <Spacer size={4} row />
        <FontAwesomeIcon
          icon={faChevronRight}
          size={"2x"}
          color={color.disclaimer}
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedYear(selectedYear + 1)}
        />
      </div>
      <Spacer size={16} />
      <div style={styles.monthsContainer}>
        {Object.entries(MONTHS_LABEL).map((month) => {
          const formattedMonth = ("00" + month[0]).slice(-2);
          const formattedDate = `${formattedMonth}-${selectedYear}`;
          const hasMonth = filledMonths.includes(formattedDate);

          return (
            <>
              <div style={styles.monthCard} key={month[0]}>
                <div
                  style={styles.monthCardRedirect}
                  onClick={() =>
                    navigate(`/analise/${month[0]}/${selectedYear}`)
                  }
                >
                  <h3>{month[1]}</h3>
                </div>
                <div style={{ display: "flex" }}>
                  <FontAwesomeIcon
                    icon={faUpload}
                    size={"2x"}
                    color={color.disclaimer}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/enviar-relatorio/${month[0]}/${selectedYear}`)
                    }
                  />
                  <Spacer size={16} row />
                  <FontAwesomeIcon
                    icon={hasMonth ? faCheckCircle : faXmarkCircle}
                    size={"2x"}
                    color={
                      hasMonth ? color.states.success : color.states.failed
                    }
                  />
                </div>
              </div>
              <Spacer size={16} row />
            </>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  titleContainer: {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: color.disclaimer,
  },
  monthCard: {
    ...card,
    width: "500px",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
  },
  monthCardRedirect: {
    flex: 1,
    cursor: "pointer",
  },
  monthsContainer: {
    display: "flex",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    justifyContent: "center",
  },
};
