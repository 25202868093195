import React, { useEffect, useState } from "react";
import Spacer from "../../components/Spacer";
import { button, card, input, inputLabel } from "../../styles/components";
import color from "../../styles/color";
import { CommissionPercentageConfig } from "../../types/Employee";
import { Seniority } from "../../types/Employee";
import {
  getComissionsPercentageConfig,
  putComissionsPercentageConfig,
} from "../../controllers/ConfigCRUD";

export default () => {
  const [comissions, setComissions] = useState<CommissionPercentageConfig>({
    Júnior: {
      mainAdvisor: 0,
      supportAdvisor: 0,
      finder: 0,
    },
    Pleno: {
      mainAdvisor: 0,
      supportAdvisor: 0,
      finder: 0,
    },
    Sênior: {
      mainAdvisor: 0,
      supportAdvisor: 0,
      finder: 0,
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getComissionsPercentageConfig()
      .then((data) => {
        if (data) setComissions(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <p>Carregando...</p>;

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Configurações</h1>
      <Spacer size={16} />

      {Object.values(Seniority).map((seniority) => (
        <>
          <h2 style={styles.seniorityDisclaimer}>{seniority}</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={styles.row}>
              <label style={inputLabel}>Assessor Principal:</label>
              <Spacer size={4} />
              <input
                type="number"
                min={0}
                max={100}
                step={0.1}
                value={comissions[seniority].mainAdvisor}
                onChange={(k) =>
                  setComissions({
                    ...comissions,
                    [seniority]: {
                      ...comissions[seniority],
                      mainAdvisor: k.target.valueAsNumber,
                    },
                  })
                }
                style={input}
              />
            </div>

            <div style={styles.row}>
              <label style={inputLabel}>Assessor de Apoio:</label>
              <Spacer size={4} />
              <input
                type="number"
                min={0}
                max={100}
                step={0.1}
                value={comissions[seniority].supportAdvisor}
                onChange={(k) =>
                  setComissions({
                    ...comissions,
                    [seniority]: {
                      ...comissions[seniority],
                      supportAdvisor: k.target.valueAsNumber,
                    },
                  })
                }
                style={card}
              />
            </div>

            <div style={styles.row}>
              <label style={inputLabel}>Finder:</label>
              <Spacer size={4} />
              <input
                type="number"
                min={0}
                max={100}
                step={0.1}
                value={comissions[seniority].finder}
                onChange={(k) =>
                  setComissions({
                    ...comissions,
                    [seniority]: {
                      ...comissions[seniority],
                      finder: k.target.valueAsNumber,
                    },
                  })
                }
                style={card}
              />
            </div>
          </div>
          <Spacer size={16} />
        </>
      ))}

      <div style={{ alignSelf: "center" }}>
        <button
          style={button}
          onClick={() =>
            putComissionsPercentageConfig(comissions)
              .then(() => {
                alert("Configurações atualizadas com sucesso");
              })
              .catch(() =>
                alert(
                  "Falha ao atualizar as configurações. Tente novamente mais tarde."
                )
              )
          }
        >
          Salvar
        </button>
      </div>
    </>
  );
};

const styles = {
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as "column",
    paddingRight: 8,
    paddingLeft: 8,
    flexWrap: "wrap" as "wrap",
  },
  seniorityDisclaimer: {
    textAlign: "center" as "center",
    backgroundColor: color.disclaimer,
    color: color.backgroundDark,
  },
};
