import React, { useContext } from "react";
import { UserContext } from "../App";
import color from "../styles/color";
import logo from "../assets/logo_meio_claro.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faGear, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import Spacer from "./Spacer";
import { useLocation, useParams } from "react-router";

interface MenuItem {
  label: string;
  route: string[];
}

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();

  return Object.values(params).reduce(
    (path, param) => path!.replace("/" + param, ""),
    location.pathname
  );
};

export default ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const menuItems: MenuItem[] = [
    {
      label: "Clientes",
      route: ["/", "/cadastrar-clientes"],
    },
    {
      label: "Assessores",
      route: ["/assessores", "/editar-assessor"],
    },
    {
      label: "Relatórios",
      route: ["/relatorios", "/enviar-relatorio"],
    },
    {
      label: "Análise",
      route: ["/analise"],
    },
  ];

  const location = useBasePath()!;
  const [uid, setUid] = useContext(UserContext);

  return (
    <div style={styles.wraper}>
      <div style={styles.container}>
        <img src={logo} width="120" style={{ objectFit: "contain" }} />
        <div style={styles.menuItemsContainer}>
          {menuItems.map((menuItem, index, arr) => (
            <React.Fragment key={index}>
              <a
                style={{
                  ...styles.menuItem,
                  color: menuItem.route.includes(location)
                    ? color.highlight
                    : color.disclaimer,
                }}
                href={
                  menuItem.route[0] !== "/analise"
                    ? menuItem.route[0]
                    : "/relatorios"
                }
              >
                {menuItem.label}
              </a>
              {index !== arr.length && <Spacer size={16} row />}
            </React.Fragment>
          ))}
        </div>
        <div style={styles.tabButtons}>
          <a href="/configuracoes">
            <FontAwesomeIcon
              icon={faGear}
              size={"2x"}
              color={
                location === "/configuracoes"
                  ? color.highlight
                  : color.disclaimer
              }
            />
          </a>
          <Spacer size={8} row />
          <FontAwesomeIcon
            icon={faRightFromBracket}
            size={"2x"}
            color={color.disclaimer}
            cursor={"pointer"}
            onClick={() => {
              if (window.confirm("Deseja realmente sair?")) {
                setUid(undefined);
              }}}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

const styles = {
  wraper: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    padding: 32,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "48px",
    flexWrap: "wrap" as "wrap",
  },
  menuItemsContainer: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap" as "wrap",
    alignSelf: "center",
    justifyContent: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  tabButtons: {
    display: "flex",
    flexDirection: "row" as "row",
  },
  menuItem: {
    fontSize: "22px",
    textDecoration: "none",
  },
};
