import {
  addDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import { Employee } from "../types/Employee";

const employeeCollectionRef = collection(db, "Employee");

export const getEmployees = async () => {
  const employees = await getDocs(employeeCollectionRef);
  if (!employees) {
    throw "Erro ao retornar assessores!";
  }

  const employeesList = employees.docs.map((doc) => ({
    ...(doc.data() as Employee),
    id: doc.id,
  }));

  return employeesList;
};

export const getEmployeeByID = async (id: string) => {
  const employeeRef = doc(db, "Employee", id);
  const employee = await getDoc(employeeRef);
  if (!employee) throw "Erro ao retornar assessor";

  return employee.data() as Employee;
};

export const putEmployee = async (Employee: Employee, id?: string) => {
  if (!id) {
    await addDoc(employeeCollectionRef, Employee);
    return;
  }
  const employeeRef = doc(db, "Employee", id);
  await setDoc(employeeRef, Employee);
};
