import React, { useEffect, useState } from "react";
import { button, card, input } from "../../styles/components";
import Spacer from "../../components/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import color from "../../styles/color";
import { Employee } from "../../types/Employee";
import { useNavigate } from "react-router";
import { getEmployees } from "../../controllers/EmployeeCRUD";

export default () => {
  const navigate = useNavigate();

  const [employeesList, setEmployeesList] = useState<Employee[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getEmployees()
      .then((data) => {
        setEmployeesList(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <p>Carregando...</p>;

  if (isError) return <p>Falha ao buscar os dados de assessores</p>;

  const filteredEmployees = employeesList!.filter(
    (employee) =>
      employee.email.toLowerCase().match(search.toLowerCase()) ||
      employee.id!.toLowerCase().match(search.toLowerCase())
  );

  return (
    <>
      <div style={styles.container}>
        <button
          type="button"
          style={button}
          onClick={() => navigate("/editar-assessor")}
        >
          Novo Assessor
        </button>
      </div>
      <Spacer size={32} />
      <input
        type="text"
        placeholder="Pesquisar"
        style={input}
        value={search}
        onChange={(input) => setSearch(input.target.value)}
      />
      <Spacer size={32} />
      {filteredEmployees.map((employee, index, arr) => (
        <>
          <div style={card} key={employee.email}>
            <div>
              <h3 style={{ margin: 0 }}>{employee.id}</h3>
              <p>
                Senioridade: <b>{employee.seniority}</b>
              </p>
              <p>
                Email: <b>{employee.email}</b>
              </p>
            </div>
            <a href={`/editar-assessor/${employee.id}`}>
              <FontAwesomeIcon
                icon={faPenToSquare}
                size={"2x"}
                color={color.disclaimer}
              />
            </a>
          </div>
          {index !== arr.length - 1 && <Spacer size={16} />}
        </>
      ))}
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-end",
  },
};
